// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
  --ion-color-primary: #a5dbd9;
  --ion-color-primary-rgb: 165, 219, 217;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #91c1bf;
  --ion-color-primary-tint: #aedfdd;
  --ion-color-secondary: #a5dbd9;
  --ion-color-secondary-rgb: 165, 219, 217;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #91c1bf;
  --ion-color-secondary-tint: #aedfdd;
  --ion-color-tertiary: #a5dbd9;
  --ion-color-tertiary-rgb: 165, 219, 217;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #91c1bf;
  --ion-color-tertiary-tint: #aedfdd;
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;
  --ion-color-dark: #0d6664;
  --ion-color-dark-rgb: 13, 102, 100;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #0b5a58;
  --ion-color-dark-tint: #257574;
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  li-ionic4-datepicker {
    input.li-dp-input {
      padding: 0 !important;
      border: none;
    }
  }
}

.ion-color-yellow {
  --ion-color-base: #ffeb3b;
  --ion-color-base-rgb: 255,255,0;
  --ion-color-contrast: #000000;
  --ion-color-contrast-rgb: 0,0,0;
  --ion-color-shade: #e0e000;
  --ion-color-tint: #ffff1a;
}

.ion-color-orange {
  --ion-color-base: #ff9800;
  --ion-color-base-rgb: 255,255,0;
  --ion-color-contrast: #000000;
  --ion-color-contrast-rgb: 0,0,0;
  --ion-color-shade: #ff9800;
  --ion-color-tint: #ff9800;
}

// custom css
.invalid {
  color: red !important;
}

.validator-error {
  color: red;
  align-self: start;
}

ion-button.grey.button {
  color: white;
  --background: rgb(171, 172, 186);
}

ion-button.turquoise.button {
  color: white;
  --background: rgb(91, 190, 187);
}

ion-button.blue.button {
  color: white;
  --background: rgb(65, 178, 207);
}

ion-button.green.button {
  color: white;
  --background: rgb(168, 217, 195);
}

ion-icon.close-icon {
  font-size: 28px;
  padding-right: 10px;
}

.flex-container {
  display: flex;
}

.sc-ion-label-md-s h2 {
  margin-top: 18px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.2;
  font-size: 20px;
  color: var(--ion-color-primary);
}

.calendars-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  .month-view {
    display: inline-block;
    vertical-align: top;
    width: 340px;
  }
}